$BACKGROUND_COLOR: #f6f6f6;
$ERROR_RED: #e3191f;
$MAIN_COLOR: #fac93f;
$MAIN_COLOR_OPACITY: rgba(250, 201, 63, 0.5);
$SECONDARY_COLOR: #ffdd00;
$SECONDARY_COLOR_OPACITY: rgba(255, 221, 0, 0.3);
$BLACK_TEXT_COLOR: #5c686d;
$GRAY_TEXT_COLOR: #d7dfe3;

:export {
  BACKGROUND_COLOR: $BACKGROUND_COLOR;
  ERROR_RED: $ERROR_RED;
  MAIN_COLOR: $MAIN_COLOR;
  MAIN_COLOR_OPACITY: $MAIN_COLOR_OPACITY;
  SECONDARY_COLOR: $SECONDARY_COLOR;
  SECONDARY_COLOR_OPACITY: $SECONDARY_COLOR_OPACITY;
  BLACK_TEXT_COLOR: $BLACK_TEXT_COLOR;
  GRAY_TEXT_COLOR: $GRAY_TEXT_COLOR;
}

body {
  --BACKGROUND_COLOR: #{$BACKGROUND_COLOR};
  --ERROR_RED: #{$ERROR_RED};
  --MAIN_COLOR: #{$MAIN_COLOR};
  --MAIN_COLOR_OPACITY: #{$MAIN_COLOR_OPACITY};
  --SECONDARY_COLOR_OPACITY: #{$SECONDARY_COLOR_OPACITY};
  --SECONDARY_COLOR: #{$SECONDARY_COLOR};
  --BLACK_TEXT_COLOR: #{$BLACK_TEXT_COLOR};
  --GRAY_TEXT_COLOR: #{$GRAY_TEXT_COLOR};
}
